/* Header.css */
header {
    background-color: #f0f0f0; /* Light grey background */
    padding: 10px 0; /* Top and bottom padding */
    box-shadow: 0 2px 4px rgba(0,0,0,0.1); /* Subtle shadow for depth */
}

nav ul {
    list-style: none; /* Remove list bullets */
    margin: 0;
    padding: 0;
    display: flex; /* Display links horizontally */
    justify-content: center; /* Center links */
}

nav ul li {
    margin: 0 15px; /* Space out links */
}

nav ul li a {
    text-decoration: none; /* Remove underline from links */
    color: #333; /* Dark text for contrast */
    font-weight: bold; /* Make text bold */
}

nav ul li a:hover {
    color: #007bff; /* Change color on hover for visual feedback */
}
