/* ReviewerPage.css */
.reviewer-container {
    display: flex;
    flex-direction: column; /* Arrange children in a column */
    padding: 6px;
}
.reviewer-container .file-info {
    margin-top: 0px;
    margin-bottom: 0px; 
}
.reviewer-container .text-info {
    margin-top: 0px;
    margin-bottom: 0px; 
}

.content {
    display: flex; /* Use flex for the main content */
}

.submissions-list {
    width: 25%; /* Take up 1/4 of the space */
    max-width: 25%; /* Ensure it doesn't exceed 1/4 of the space */
    overflow-x: auto; /* Horizontal scroll */
    overflow-y: hidden; /* No vertical scroll */
    white-space: nowrap; /* Prevent wrapping of content */
    margin-right: 20px; /* Space between list and details */
}

.submission-details {
    flex-grow: 1; /* Take up the rest of the space */
}

.comment-section {
    margin-bottom: 20px; /* Add some space below the comment section */
}

.comment-section textarea {
    width: 100%; /* Make textarea take the full width */
    margin-bottom: 10px; /* Add some space between textarea and button */
}

.comment-section button {
    /* Style as needed */
}
